import _taggedTemplateLiteral from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import gql from "graphql-tag";
export var CREATE_DEPARTMENT = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation($input: DepartmentInput) {\n    createDepartment(input: $input) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var UPDATE_DEPARTMENT = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation($input: DepartmentInput) {\n    updateDepartment(input: $input) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var DELETE_DEPARTMENT = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation($id: ID) {\n    deleteDepartment(_id: $id) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var GET_DEPARTMENTS = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query {\n    departments {\n      isSuccess\n      message\n      records {\n        _id\n        dept_id\n        dept_name\n        dept_desc\n        no\n        is_show\n        noti\n      }\n    }\n  }\n"])));
export var GET_ADMIN_DEPARTMENTS = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  query {\n    systemDepartmentList {\n      isSuccess\n      message\n      records {\n        _id\n        dept_id\n        dept_name\n        dept_desc\n        no\n        is_show\n        noti\n        last_message {\n          created_at\n        }\n      }\n    }\n  }\n"])));
export var GET_ADMIN_DEPARTMENT = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query($_id: ID) {\n    systemDepartment(_id: $_id) {\n      isSuccess\n      message\n      data {\n        _id\n        dept_id\n        dept_name\n        dept_desc\n        no\n      }\n    }\n  }\n"])));